export const bankAccountHGuideExceptionHandler = (
  associationCd: string
): boolean => {
  const exceptionCodes = new Set([
    '008616',
    '086160',
    '001202',
    '002207',
    '002669',
    '007434',
    '011883',
    '012926',
    '025510',
    '064801',
    '066551',
    '996602',
  ]);

  return exceptionCodes.has(associationCd);
};
