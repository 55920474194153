import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { UserInfo } from '@/components/molecules/Table/UserInfo';
import { HomeHeader } from '@/components/organism/HomeHeader';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { FAQ } from '@/components/organism/Apply/Faq';
import { WarmingDeadLine } from '@/components/organism/Apply/WarmingDeadLine';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { BREAKPOINT, theme } from '@/theme/theme';
import { SelectBox } from '@/components/atoms/Input/SelectBox';
import { reasonsForWithdrawal } from '@/constants/choicesForSelectBox';
import { Path } from '@/constants/Router/path';
import { PrimaryTableBodyTdSingle } from '@/components/atoms/Table/PrimaryTableBodyTdSingle';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { AccountOpeningTip } from '@/components/organism/Apply/AccountOpeningTip';
import { useHomeHooks } from '@/components/pages/Home/hooks/useHomeHooks';
import { useAuthUser } from '@/hooks/useAuth';
import { appClient } from '@/services';
import { ConvertAssociation } from '@/services/convert/association';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { ConvertEventsmemberdeadlines } from '@/services/convert/eventsmemberdeadlines';
import { ConvertUserContribution } from '@/services/convert/userContribution';
import { Association } from '@/types/api/association';
import { AssociationContribution } from '@/types/api/associationContribution';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { UserContribution } from '@/types/api/userContribution';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import {
  CreateApplication,
  FindApplicationById,
} from '@/types/api/applications';
import { User } from '@/types/api/user';
import { UserAsset } from '@/types/api/userAsset';
import { isObjectEmpty } from '@/utils/empty';
import { InputStringProcessor, IsNumeric } from '@/utils/stringProcessor';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { useSnackbarMessage } from '@/hooks';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrraper = styled('div')(() => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    marginBottom: '80px',
    padding: '14px 0',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const ContentWrraper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});

const SpFlatRadioGroup = styled(RadioGroup)(() => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'row',
    [breakpoints.down(BREAKPOINT)]: {
      display: 'block',
      label: {
        display: 'block',
      },
    },
  };
});

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '退会する',
    to: Path.WITHDRAWAL,
  },
];

// タイトルの説明文
const description = [
  '持株会から退会をします。単元株以上の株式は、証券口座に移管され、株で移管できない分は精算金として銀行口座に振り込まれます。',
];

type Withdrawal = CreateApplication['applicationWithdrawal'];
export type Reason = Exclude<Withdrawal, undefined>['reason'];
export type LocationState = { user: User } & { association: Association } & {
  associationContribution: AssociationContribution;
} & { userContribution: UserContribution } & {
  eventsmemberdeadlines: Eventsmemberdeadlines;
} & { userAsset: UserAsset } & { application: FindApplicationById } & {
  requestParams: Withdrawal;
} & {
  branchCode: string;
  branchName: string;
  accountNumber: string;
  bonusUnit?: number;
  isBonus?: boolean;
  title?: string;
  contentTitle?: string;
  type: 'BONUS' | 'WITHDRAWAL' | 'RECESS' | 'RESUME' | 'MONTHLY' | 'PART';
  value: number;
  reason?: string;
  otherReasons?: string;
  stocksNumber?: number;
  payUnit?: number;
  findSecuritiesAccount?: Partial<{
    branchCd?: string;
    branchName?: string;
    accountNumber?: string;
    fixedFlg?: boolean;
  }>;
  bonusMultiplierDesignationType:
    | 'UNIT_NUMBER'
    | 'MAGNIFICATION'
    | 'FIXED_MAGNIFICATION';
  isBonusToggle: boolean;
  bonusUnitAmount: number;
  bonusContributionAmount: number;
  bonusIncentive: number;
  bonusRate: number;
};

export const WithdrawalInput: FC = () => {
  const isPc = useMediaQuery(() => theme.breakpoints.up(BREAKPOINT));
  const { showMessage } = useSnackbarMessage();
  const navigate = useNavigate();
  useHelmetHandler({
    title: '退会 入力画面',
  });

  const location = useLocation();
  const state = location.state as LocationState;
  const [requestParams, setRequestParams] = useState<Withdrawal>({
    isAdditionalOption: false,
    institutionType: 'OTHER',
    generalAccount: {
      accountType: 'SAVINGS',
    },
  });

  const [association, setAssociation] = useState<Association>();
  const [userContribution, setUserContribution] = useState<UserContribution>();
  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();
  const [eventsmemberdeadlines, setEventsmemberdeadlines] =
    useState<Eventsmemberdeadlines>();
  // const [securitiesEmpty, setSecuritiesEmpty] = useState(false);
  const [branchCode, setBranchCode] = useState('');
  const [branchName, setBranchName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const { isListedClassification } = useHomeHooks();

  // 【API-A-0909】資産情報取得
  const { user } = useAuthUser();

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationById } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationEventMemberDeadlines } = useSWR(
    '/api/associations/{associationCd}/eventsmemberdeadlines',
    () => appClient.associations.findAssociationEventMemberDeadlines()
  );

  // 【API-A-0905】拠出情報取得
  const { data: findUserContribution } = useSWR('/api/users/contribution', () =>
    appClient.users.findUserContribution()
  );

  // 【API-A-0906】証券口座情報取得
  const { data: findSecuritiesAccount } = useSWR(
    '/api/securitiesaccounts',
    () => appClient.users.findSecuritiesAccount()
  );

  // 【API-A-0909】資産情報取得
  const { data: userAsset } = useSWR('/api/users/assets', () =>
    appClient.users.findUserAsset()
  );

  const retrieveBranchName = async () => {
    try {
      if (branchCode) {
        const res = await appClient.users.findBranch(branchCode);
        if (res.branchName) setBranchName(res.branchName);
        else {
          throw Error('Branch name is nor valid');
        }
      }
    } catch (e) {
      // Handle error
      showMessage({
        open: true,
        messages: ['部店コードが存在しません'],
        messageType: 'warning',
      });
    }
  };

  const canContinue = useCallback((): boolean => {
    let returnParam = false;

    let CheckValues = [
      branchCode || '',
      branchName || '',
      accountNumber || '',
      requestParams?.reason || '',
    ];

    if (requestParams?.institutionType === 'OTHER') {
      // 一度だけ定義する
      const generalAccountValues = isListedClassification
        ? [
            requestParams.generalAccount?.accountNumber || '',
            requestParams.generalAccount?.accountType || '',
            requestParams.generalAccount?.bankBranchCode || '',
            requestParams.generalAccount?.bankBranchName || '',
            requestParams.generalAccount?.institutionCode || '',
            requestParams.generalAccount?.institutionName || '',
          ]
        : [
            requestParams.generalAccount?.bankBranchName || '',
            requestParams.generalAccount?.institutionCode || '',
            requestParams.generalAccount?.institutionName || '',
          ];

      // CheckValues に必要なら追加する
      CheckValues = [...CheckValues, ...generalAccountValues];

      // generalAccountValues に欠けている値があるか確認
      returnParam = generalAccountValues.some((item) => item === '');

      // isListedClassification が true の場合、追加のチェックを実行
      if (
        isListedClassification &&
        (branchCode.length < 3 || accountNumber.length < 7)
      ) {
        returnParam = true;
      }
    } else {
      CheckValues = [
        ...CheckValues,
        requestParams?.accountYuchoNumber || '',
        requestParams?.accountYuchoSymbol || '',
      ];

      const accountNumberLength = requestParams?.accountYuchoNumber
        ? Number(requestParams?.accountYuchoNumber?.length)
        : 0;
      const accountYuchoSymbolLength = requestParams?.accountYuchoSymbol
        ? Number(requestParams?.accountYuchoSymbol?.length)
        : 0;
      if (accountNumberLength < 1 || accountYuchoSymbolLength < 1) {
        returnParam = true;
      }
    }

    // Perform validation check
    return returnParam;
  }, [requestParams, branchCode, branchName, accountNumber]);

  const creatSecuritiesAccount = () => {
    let returnParam = null;

    if (isListedClassification) {
      if (
        !findSecuritiesAccount ||
        ((findSecuritiesAccount.accountNumber || '') === '' &&
          (findSecuritiesAccount.branchCd || '') === '' &&
          (findSecuritiesAccount.branchName || '') === '')
      ) {
        returnParam = (
          <>
            <PrimaryTable title="証券口座">
              <PrimaryTableBodyTdMulti
                title="金融機関名"
                row={1}
                spWidth="130px"
              >
                <Typography variant="body-main/bold">東海東京証券</Typography>
              </PrimaryTableBodyTdMulti>
              <PrimaryTableBodyTdMulti
                title="部店コード"
                subTitle="※半角数字3桁"
                row={1}
                spWidth="130px"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TextInput
                  name="branchCode"
                  value={branchCode}
                  onChange={(event) => {
                    if (!event.target.value) {
                      setBranchCode(event.target.value);
                    }
                    if (
                      IsNumeric(event.target.value) &&
                      event.target.value.length <= 3 &&
                      event.target.value.length > 0
                    ) {
                      setBranchCode(event.target.value);
                    }
                  }}
                />
                <SecondaryButton
                  onClick={() => {
                    void retrieveBranchName();
                  }}
                  sx={{
                    marginLeft: '16px',
                    minWidth: '138px',
                    fontSize: '14px',
                  }}
                >
                  部店名を自動で入力
                </SecondaryButton>
              </PrimaryTableBodyTdMulti>
              <PrimaryTableBodyTdMulti title="部店名" row={1} spWidth="130px">
                {branchName === '' ? (
                  <Typography
                    component="p"
                    variant="body-main/regular"
                    sx={{ color: 'system.text-light' }}
                  >
                    部店名が表示されます
                  </Typography>
                ) : (
                  <Typography component="p" variant="body-main/regular">
                    {branchName}
                  </Typography>
                )}
              </PrimaryTableBodyTdMulti>
              <PrimaryTableBodyTdMulti
                title="口座番号"
                subTitle="※半角数字7桁"
                row={1}
                spWidth="130px"
              >
                <TextInput
                  name="accountNumber"
                  value={accountNumber}
                  onChange={(event) => {
                    if (!event.target.value) {
                      setAccountNumber(event.target.value);
                    }
                    if (
                      IsNumeric(event.target.value) &&
                      event.target.value.length <= 7
                    ) {
                      setAccountNumber(event.target.value);
                    }
                  }}
                />
              </PrimaryTableBodyTdMulti>
            </PrimaryTable>
            <AccountOpeningTip />
          </>
        );
      } else {
        returnParam = (
          <BasicTable
            title="証券口座"
            type="securities"
            name="東海東京証券"
            departmentCode={findSecuritiesAccount?.branchCd ?? ''}
            departmentName={findSecuritiesAccount?.branchName ?? ''}
            accountNumber={findSecuritiesAccount?.accountNumber ?? ''}
          />
        );
      }
    }

    return returnParam;
  };

  useEffect(() => {
    setAssociation(ConvertAssociation(findAssociationById));
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
    setEventsmemberdeadlines(
      ConvertEventsmemberdeadlines(
        findAssociationEventMemberDeadlines?.eventmemberdeadlines
      )
    );
    setUserContribution(ConvertUserContribution(findUserContribution));
    if (isObjectEmpty(findSecuritiesAccount ?? {})) {
      // setSecuritiesEmpty(true);
    } else {
      // setSecuritiesEmpty(false);
      setBranchCode(findSecuritiesAccount?.branchCd ?? '');
      setBranchName(findSecuritiesAccount?.branchName ?? '');
      setAccountNumber(findSecuritiesAccount?.accountNumber ?? '');
    }
  }, [
    findAssociationById,
    findAssociationContribution,
    findAssociationEventMemberDeadlines,
    findUserContribution,
    findSecuritiesAccount,
  ]);

  useEffect(() => {
    if (state) {
      setRequestParams(state.requestParams);
    }
  }, []);

  if (
    association &&
    associationContribution &&
    userContribution &&
    eventsmemberdeadlines &&
    userAsset
  ) {
    return (
      <Wrraper>
        <HomeHeader links={links} title="退会する" description={description} />
        <TextWithFlowIcon flowOn={0} widthFull />
        <FAQ pageName="withdrawal">
          <ContentWrraper>
            <WarmingDeadLine
              eventsmemberdeadlines={eventsmemberdeadlines}
              target="WITHDRAWAL"
            />
            <UserInfo
              mochikabukaiCode={user.associationCd ?? ''}
              mochikabukaiName={association.associationName ?? ''}
              nameKanji={user.nameKanji ?? ''}
              nameKana={user.nameKana ?? ''}
              zipcode={user.zipcode ?? ''}
              address1={user.address1 ?? ''}
              address2={user.address2 ?? ''}
              address3={user.address3 ?? ''}
              tel={user.tel ?? ''}
              employeeCd={user.employeeCd ?? ''}
              editable
            />
            <BasicTable
              boxSx={{ width: '100%' }}
              title="現在の積立状況"
              type="reserve"
              stocksNumber={userAsset.stocksNumber}
              unitAmount={associationContribution.unitAmount}
              monthlyUnit={userContribution.monthlyUnit}
              bonusUnit={userContribution.bonusUnit}
              investmentDigit={association.investmentDigit}
            />
            {creatSecuritiesAccount()}
            {/* 口座未開設時 */}
            <PrimaryTable title="退会理由">
              <PrimaryTableBodyTdMulti title="退会理由" spWidth="130px">
                <SelectBox
                  items={reasonsForWithdrawal}
                  width={isPc ? '320px' : '255px'}
                  value={requestParams?.reason ?? ''}
                  onChange={(event) => {
                    setRequestParams((current) => ({
                      ...current,
                      reason: event.target.value as Reason,
                    }));
                  }}
                />
                {requestParams?.reason === 'OTHER' && (
                  <Box sx={{ marginTop: '16px' }}>
                    <TextInput
                      name="otherReasons"
                      value={requestParams?.otherReasons ?? ''}
                      onChange={(event) => {
                        setRequestParams((current) => ({
                          ...current,
                          otherReasons: event.target.value,
                        }));
                      }}
                    />
                  </Box>
                )}
              </PrimaryTableBodyTdMulti>
            </PrimaryTable>
            {association.withdrawalAllSettlementUsageClassification === '0' && (
              <PrimaryTable title="精算方法">
                <PrimaryTableBodyTdSingle>
                  100株未満の株式を現金精算するか、100株まで買増しして退会するかご選択ください。
                </PrimaryTableBodyTdSingle>
                <PrimaryTableBodyTdMulti
                  title="売買単位"
                  SecondaryTitle="未満株式持分"
                  row={1}
                  spWidth="130px"
                >
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="yes"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="持株会に売却（単元株以上は証券口座に移管）"
                        checked={requestParams?.isAdditionalOption === false}
                        onClick={() => {
                          setRequestParams((current) => ({
                            ...current,
                            isAdditionalOption: false,
                          }));
                        }}
                      />
                      {association.withdrawalAddPurchaseUsageClassification ===
                        '1' && (
                        <FormControlLabel
                          control={<Radio />}
                          label="臨時拠出により買増し"
                          checked={requestParams?.isAdditionalOption === true}
                          onClick={() => {
                            setRequestParams((current) => ({
                              ...current,
                              isAdditionalOption: true,
                            }));
                          }}
                        />
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          marginLeft: '30px',
                          color: 'system.text-light',
                        }}
                      >
                        <Typography variant="body-sub/regular">
                          買増株数
                        </Typography>
                        <Typography
                          variant="body-sub/bold"
                          sx={{ marginLeft: '8px' }}
                        >
                          {userAsset.stocksNumber
                            ? `${(
                                100 -
                                (userAsset.stocksNumber % 100)
                              ).toLocaleString(undefined, {
                                maximumFractionDigits:
                                  association.investmentDigit,
                              })}株`
                            : '0株'}
                        </Typography>
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </PrimaryTableBodyTdMulti>
              </PrimaryTable>
            )}
            <PrimaryTable title="精算金振込口座(会員名義口座)">
              <PrimaryTableBodyTdSingle>
                退会精算金を振込みする際に必要となりますので、会員本人名義の銀行口座情報を入力してください。
              </PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdMulti
                title="金融機関の種類"
                row={1}
                spWidth="130px"
              >
                <FormControl>
                  <SpFlatRadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="1"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ゆうちょ銀行以外"
                      checked={requestParams?.institutionType === 'OTHER'}
                      onClick={() => {
                        setRequestParams((current) => ({
                          ...current,
                          institutionType: 'OTHER',
                        }));
                      }}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="ゆうちょ銀行"
                      checked={requestParams?.institutionType === 'YUCHO'}
                      onClick={() => {
                        setRequestParams((current) => ({
                          ...current,
                          institutionType: 'YUCHO',
                        }));
                      }}
                    />
                  </SpFlatRadioGroup>
                </FormControl>
              </PrimaryTableBodyTdMulti>
              {requestParams?.institutionType === 'OTHER' && (
                <>
                  <PrimaryTableBodyTdMulti
                    title="金融機関コード"
                    subTitle="※半角数字4桁"
                    row={1}
                    spWidth="130px"
                  >
                    <TextInput
                      name="generalAccount.institutionCode"
                      value={
                        requestParams?.generalAccount?.institutionCode ?? ''
                      }
                      onChange={(event) => {
                        if (!event.target.value) {
                          setRequestParams((current) => {
                            const tmpGeneralAccount = current?.generalAccount
                              ? {
                                  ...current?.generalAccount,
                                }
                              : {};
                            return {
                              ...current,
                              generalAccount: {
                                ...tmpGeneralAccount,
                                institutionCode: event.target.value,
                              },
                            };
                          });
                        }
                        if (
                          IsNumeric(event.target.value) &&
                          event.target.value.length <= 4
                        ) {
                          setRequestParams((current) => {
                            const tmpGeneralAccount = current?.generalAccount
                              ? {
                                  ...current?.generalAccount,
                                }
                              : {};
                            return {
                              ...current,
                              generalAccount: {
                                ...tmpGeneralAccount,
                                institutionCode: event.target.value,
                              },
                            };
                          });
                        }
                      }}
                    />
                  </PrimaryTableBodyTdMulti>
                  <PrimaryTableBodyTdMulti
                    title="金融機関名"
                    row={1}
                    spWidth="130px"
                  >
                    <TextInput
                      name="generalAccount.institutionName"
                      value={
                        requestParams?.generalAccount?.institutionName ?? ''
                      }
                      onChange={(event) => {
                        if (event.target.value.length > 18) return;
                        setRequestParams((current) => {
                          const tmpGeneralAccount = current?.generalAccount
                            ? {
                                ...current?.generalAccount,
                              }
                            : {};
                          return {
                            ...current,
                            generalAccount: {
                              ...tmpGeneralAccount,
                              institutionName: event.target.value,
                            },
                          };
                        });
                      }}
                    />
                  </PrimaryTableBodyTdMulti>
                  <PrimaryTableBodyTdMulti
                    title="支店コード"
                    subTitle="※半角数字3桁"
                    row={1}
                    spWidth="130px"
                  >
                    <TextInput
                      name="generalAccount.bankBranchCode"
                      value={
                        requestParams?.generalAccount?.bankBranchCode ?? ''
                      }
                      onChange={(event) => {
                        if (!event.target.value) {
                          setRequestParams((current) => {
                            const tmpGeneralAccount = current?.generalAccount
                              ? {
                                  ...current?.generalAccount,
                                }
                              : {};
                            return {
                              ...current,
                              generalAccount: {
                                ...tmpGeneralAccount,
                                bankBranchCode: event.target.value,
                              },
                            };
                          });
                        }
                        if (
                          IsNumeric(event.target.value) &&
                          event.target.value.length <= 3
                        ) {
                          setRequestParams((current) => {
                            const tmpGeneralAccount = current?.generalAccount
                              ? {
                                  ...current?.generalAccount,
                                }
                              : {};
                            return {
                              ...current,
                              generalAccount: {
                                ...tmpGeneralAccount,
                                bankBranchCode: event.target.value,
                              },
                            };
                          });
                        }
                      }}
                    />
                  </PrimaryTableBodyTdMulti>
                  <PrimaryTableBodyTdMulti
                    title="支店名"
                    row={1}
                    spWidth="130px"
                  >
                    <TextInput
                      name="generalAccount.bankBranchName"
                      value={requestParams.generalAccount?.bankBranchName}
                      onChange={(event) => {
                        if (event.target.value.length > 18) return;
                        setRequestParams((current) => {
                          const tmpGeneralAccount = current?.generalAccount
                            ? {
                                ...current?.generalAccount,
                              }
                            : {};
                          return {
                            ...current,
                            generalAccount: {
                              ...tmpGeneralAccount,
                              bankBranchName: event.target.value,
                            },
                          };
                        });
                      }}
                    />
                  </PrimaryTableBodyTdMulti>
                  <PrimaryTableBodyTdMulti
                    title="預金種別"
                    row={1}
                    spWidth="130px"
                  >
                    <FormControl>
                      <SpFlatRadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="type1"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          control={<Radio />}
                          checked={
                            requestParams.generalAccount?.accountType ===
                            'SAVINGS'
                          }
                          label="普通"
                          onClick={() => {
                            setRequestParams((current) => {
                              const tmpGeneralAccount = current?.generalAccount
                                ? {
                                    ...current?.generalAccount,
                                  }
                                : {};
                              return {
                                ...current,
                                generalAccount: {
                                  ...tmpGeneralAccount,
                                  accountType: 'SAVINGS',
                                },
                              };
                            });
                          }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="当座"
                          checked={
                            requestParams.generalAccount?.accountType ===
                            'CURRENT'
                          }
                          onClick={() => {
                            setRequestParams((current) => {
                              const tmpGeneralAccount = current?.generalAccount
                                ? {
                                    ...current?.generalAccount,
                                  }
                                : {};
                              return {
                                ...current,
                                generalAccount: {
                                  ...tmpGeneralAccount,
                                  accountType: 'CURRENT',
                                },
                              };
                            });
                          }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="貯蓄"
                          checked={
                            requestParams.generalAccount?.accountType ===
                            'OTHER'
                          }
                          onClick={() => {
                            setRequestParams((current) => {
                              const tmpGeneralAccount = current?.generalAccount
                                ? {
                                    ...current?.generalAccount,
                                  }
                                : {};
                              return {
                                ...current,
                                generalAccount: {
                                  ...tmpGeneralAccount,
                                  accountType: 'OTHER',
                                },
                              };
                            });
                          }}
                        />
                      </SpFlatRadioGroup>
                    </FormControl>
                  </PrimaryTableBodyTdMulti>
                  <PrimaryTableBodyTdMulti
                    title="口座番号"
                    subTitle="※半角数字7桁"
                    row={1}
                    spWidth="130px"
                  >
                    <TextInput
                      name="generalAccount.accountNumber"
                      value={requestParams.generalAccount?.accountNumber ?? ''}
                      onChange={(event) => {
                        if (!event.target.value) {
                          setRequestParams((current) => {
                            const tmpGeneralAccount = current?.generalAccount
                              ? {
                                  ...current?.generalAccount,
                                }
                              : {};
                            return {
                              ...current,
                              generalAccount: {
                                ...tmpGeneralAccount,
                                accountNumber: event.target.value,
                              },
                            };
                          });
                        }
                        if (
                          IsNumeric(event.target.value) &&
                          event.target.value.length <= 7
                        ) {
                          setRequestParams((current) => {
                            const tmpGeneralAccount = current?.generalAccount
                              ? {
                                  ...current?.generalAccount,
                                }
                              : {};
                            return {
                              ...current,
                              generalAccount: {
                                ...tmpGeneralAccount,
                                accountNumber: event.target.value,
                              },
                            };
                          });
                        }
                      }}
                    />
                  </PrimaryTableBodyTdMulti>
                </>
              )}
              {requestParams?.institutionType === 'YUCHO' && (
                <PrimaryTableBodyTdMulti
                  title="店番・番号"
                  row={1}
                  spWidth="130px"
                >
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Box>
                      <Typography
                        variant="body-sub/regular"
                        sx={{ display: 'block', marginBottom: '4px' }}
                      >
                        店番
                      </Typography>
                      <TextInput
                        name="accountYuchoSymbol"
                        value={requestParams.accountYuchoSymbol}
                        onChange={(event) => {
                          if (!event.target.value) {
                            setRequestParams((current) => ({
                              ...current,
                              accountYuchoSymbol: event.target.value,
                            }));
                          }
                          if (
                            IsNumeric(event.target.value) &&
                            event.target.value.length >= 1 &&
                            event.target.value.length <= 5
                          ) {
                            setRequestParams((current) => ({
                              ...current,
                              accountYuchoSymbol: event.target.value,
                            }));
                          }
                        }}
                      />
                    </Box>
                    <Typography
                      variant="body-sub/regular"
                      sx={{ display: 'block', margin: '0 8px 8px' }}
                    >
                      -
                    </Typography>
                    <Box>
                      <Typography
                        variant="body-sub/regular"
                        sx={{ display: 'block', marginBottom: '4px' }}
                      >
                        番号
                      </Typography>
                      <TextInput
                        name="accountYuchoNumber"
                        value={requestParams.accountYuchoNumber}
                        onChange={(event) => {
                          if (!event.target.value) {
                            setRequestParams((current) => ({
                              ...current,
                              accountYuchoNumber: event.target.value,
                            }));
                          }
                          if (
                            IsNumeric(event.target.value) &&
                            event.target.value.length >= 1 &&
                            event.target.value.length <= 8
                          ) {
                            setRequestParams((current) => ({
                              ...current,
                              accountYuchoNumber: event.target.value,
                            }));
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </PrimaryTableBodyTdMulti>
              )}
            </PrimaryTable>
            <Stack
              padding="8px 16px"
              border="1px solid"
              borderColor={theme.palette.states.error}
              borderRadius="8px"
              color={theme.palette.states.error}
              sx={{
                [theme.breakpoints.down(BREAKPOINT)]: {
                  margin: '0 16px',
                },
              }}
            >
              <Typography variant="body-main/bold">
                売却時の注意事項{' '}
              </Typography>
              <Typography variant="body-main/regular">
                会社の株価に重大な影響を与える「重要事実」を知りながら、その重要事実が「公表される前」に、会社の株式の売買等を行うことはできません。売却にあたりましては、必ず事前に会社に許可を得てください。
              </Typography>
            </Stack>
            <PrimaryButton
              sx={{
                padding: '16px 40px',
                borderRadius: '28px',
                alignSelf: 'center',
              }}
              disabled={canContinue()}
              onClick={() =>
                navigate(Path.WITHDRAWAL_CONFIRM, {
                  state: {
                    user,
                    association,
                    associationContribution,
                    userContribution,
                    eventsmemberdeadlines,
                    userAsset,
                    findSecuritiesAccount,
                    requestParams: {
                      ...requestParams,
                      generalAccount: {
                        ...requestParams?.generalAccount,
                        institutionName:
                          InputStringProcessor(
                            requestParams?.generalAccount?.institutionName
                          ) || '',
                        bankBranchName: InputStringProcessor(
                          requestParams?.generalAccount?.bankBranchName
                        ),
                      },
                    },
                    branchCode: InputStringProcessor(branchCode) || '',
                    branchName: InputStringProcessor(branchName) || '',
                    accountNumber,
                  },
                })
              }
            >
              確認へ進む
            </PrimaryButton>
          </ContentWrraper>
        </FAQ>
      </Wrraper>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
